#presentations {
  ul {
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: 50px;
  }
  .video-presentation {
    h1 {
      text-align: center;
    }
    h3 {
      text-align: center;
    }

    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
