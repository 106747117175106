/* Resume */

#resume {
  .link-to {
    position: relative;
    top: -4.5em;
  }

  .link-container h4 {
    text-decoration: none;
    border-bottom: none;
    display: inline-flex;

    a {
      padding: 0 0.5em;
      margin: 0;
    }
  }

  .education {
    .title {
      text-align: center;
      margin-bottom: 2em;
    }
  }

  .experience {
    .title {
      text-align: center;
    }
    .job {
      margin: 1em;
      p {
        margin: 0;
      }
    }
  }

  .jobs-container {
    .points {
      li {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
      }
    }
  }

  .skills {
    margin-top: 2em;
    .title {
      text-align: center;
      p {
        font-size: 0.9em;
      }
    }
  }
  .references {
    margin: 2em 0;
    .title {
      text-align: center;
    }
  }
}

.daterange {
  margin-bottom: 0.2em;
}

.degree-container {
  h4 {
    font-weight: normal;
  }

  .degree {
    margin-bottom: 0.1em;
    text-transform: none;
    letter-spacing: 0.16em;
    font-size: 0.8em;
  }

  .school {
    text-transform: none;
    padding-top: 0.3em;
    margin-bottom: 1em;
  }
}

.publications {
  .title {
    text-align: center;
  }
  .pubs-list {
    li {
      display: inline-block;
      padding-bottom: 10px;
      margin-bottom: 10px;
      line-height: 1;
    }
  }

  .pub-container {
    h4 {
      margin-bottom: 0;
    }
    .authors {
      line-height: 1em;

      li {
        display: inline-block;
        white-space: normal;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
      li::after {
        font-size: 10px;
        font-weight: 900;
        content: ", ";
      }
      li:last-child::after {
        content: "";
      }
      .author {
        margin-left: 0px;
        margin-right: 4px;
        color: #004f5b;
        font-size: 8px;
        font-weight: "normal";
        strong {
          font-weight: 900;
          font-size: 10px;
        }
      }
    }
  }
}

.presentations {
  .title {
    text-align: center;
  }
  .conf-list {
    li {
      display: inline-block;
      padding-bottom: 10px;
      margin-bottom: 10px;
      line-height: 1;
    }
  }

  .pres-container {
    h4 {
      margin-bottom: 0;
    }
  }
}
